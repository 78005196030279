<template>
  <div class="partner" v-if="list.length">
    <div class="border">
      <div class="title">合作方</div>
    </div>
    <div class="spacing">
      <div v-for="(item,index) in list" :key="index" class="flex partnerBox">
        <img :src="item.user.avatarUrl" alt="">
        <div class="introduction">
          <div class="flex_jcsb_ac">
            <div class="company flex_ac">{{item.user.nickName}}
              <div :class="item.certification_type === 1 ? 'indivualText' : 'enterpriseText'">{{item.certification_type === 1 ? '个' : '企'}}</div>
            </div>
            <el-button class="participateInBtn" @click="chooseClick(item.id)">选择TA</el-button>
          </div>
          <div class="text">姓名：{{item.name}}</div>
          <div class="text">联系电话：{{item.phone}}</div>
          <div class="text">备注：{{item.remarks}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { selection } from '@/api/index/index'
export default {
  props: {
    // projectId: { // 项目ID
    //   type: String | Number,
    //   default: ''
    // },
    // projectUid: { // 企业用户ID
    //   type: Number,
    //   default: 0
    // },
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // partnerList: []
    }
  },
  mounted() {
    // this.listClick()
  },
  methods: {
    chooseClick(id) {
      const data = {
        id
      }
      selection(data).then(res => {
        if (res.code === 1) {
          this.$message.success('选标成功')
          this.$emit('chooseProjesct')
          // this.$router.push({name: 'detailsProjesct', query: {id}})
        } else {
          this.$message.success(res.mes)
        }
      })
    }
    // listClick() {
    //   const data = {
    //     project_uid: this.projectUid, // 企业用户ID
    //     page: 1,
    //     psize: 100000,
    //     project_id: this.projectId // 项目ID
    //   }
    //   indexTender(data).then(res => {
    //     this.partnerList = res.data.list
    //     // console.log(res)
    //   })
    // }
  }
}
</script>
<style lang="scss" scoped>
  .partner{
    background: #fff;
    margin-bottom: 12px;
    .spacing{
      padding: 24px;
      .partnerBox{
        background: #F7F9FC;
        padding: 16px 18px 18px 24px;
        margin-bottom: 12px;
        .introduction{
          margin-left: 25px;
          flex: 1;
          .company{
            font-size: 18px;
          }
          .text:nth-child(1){
            margin-top: 23px;
          }
          .text{
            color: #666;
            font-size: 16px;
            line-height: 29px;
          }
        }
        img{
          width: 144px;
          height: 144px;
          background: #FFFFFF;
        }
      }
    }
    .border{
      padding: 24px;
      border-bottom: 1px solid #EEE;
      .title{
        font-size: 18px;
        border-left: 4px solid #1166CF;
        padding-left: 12px;
      }
    }
  }
</style>
