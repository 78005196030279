<template>
  <div class="detailsProjesct">
    <div class="basic">
      <!-- <img class="bg" src="../../assets/index.jpg" /> -->
      <div class="datailsBox flex_jcsb">
        <div class="left flex">
          <img :src="info.pic" alt="">
          <div class="introduce">
            <div class="name">{{info.name}}</div>
            <div class="assort" v-if="info.cateOne || info.cateTwo">{{info.cateOne.name || ''}} - {{info.cateTwo.name || ''}}</div>
            <div class="flex_ac dataTiem">
              <i class="el-icon-timer"></i>
              <span class="tiem">{{info.create_time}}</span>
              <i class="el-icon-view"></i>
              <span class="tiem">{{info.view}}</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="address" v-if="info.city">{{info.city.name}}</div>
          <div class="focusOn flex_ac cursor" v-if="role === 1 && info.is_follow === 0" @click="followProject(info.id)">
            <img class="img" src="../../assets/images/icon/guanzhu.d.png" />
            <span>关注项目</span>
          </div>
          <div class="focusOn flex_ac cursor" v-if="role === 1 && info.is_follow === 1" @click="followCancel(info.id)">
            <img src="../../assets/images/icon/guanzhu.s.png" />
            <span>关注项目</span>
          </div>
          <el-button v-if="info.button === 1" style="width:160px" class="participateInBtn joinBtn" @click="participateClick">我要参与</el-button>
        </div>
      </div>
    </div>
    <div class="businessBox flex">
      <div class="widthBox">
        <!-- 合作方 -->
        <Partner :list="partnerList" v-if="info.button != 0 && info.is_my && partnerList.length && info.status != 2 && info.status != 0 && info.button != 4 && info.button !=8" @chooseProjesct="chooseProjesct" />
        <div class="paddingIn">
          <div class="border">
            <div class="title">项目详情</div>
          </div>
          <div class="borderBox">
            <!-- <el-table :data="info.table_data" border stripe :show-header="false">
              <el-table-column prop="title" label="日期" width="184"></el-table-column>
              <el-table-column prop="name" label="姓名" width="600"></el-table-column>
            </el-table> -->
            <div v-for="(item, index) in info.table_data" :key="index" :class="index % 2 === 0 ?'flex back' : 'flex'">
              <div class="title">{{item.title}}</div>
              <div class="nameBox flex_jc_ac">
                <div v-if="userInfo && userInfo.phone">
                  <span class="name" v-if="item.level === 1">
                    <span class="name" v-if="item.title === '项目描述'" v-html="item.name"></span>
                    <span class="name" v-else>{{item.name}}</span>
                  </span>
                  <span class="name" v-else-if="userInfo.is_vip === 1">
                    <span class="name" v-if="item.title === '项目描述'" v-html="item.name"></span>
                    <span class="name" v-else>{{item.name}}</span>
                  </span>
                  <span class="name" v-else-if="userInfo.id === info.user.id">
                    <span class="name" v-if="item.title === '项目描述'" v-html="item.name"></span>
                    <span class="name" v-else>{{item.name}}</span>
                  </span>
                  <div v-else>
                    <span class="name" v-if="item.title === '项目描述'" v-html="item.name"></span>
                    <span class="name" v-else>{{fileConet(item.name)}}</span>
                  </div>
                </div>
                <div v-else>
                  <span class="name" v-if="item.title === '联系电话'">{{fileConet(item.name)}}</span>
                  <span class="name" v-else-if="item.title === '项目描述'" v-html="item.name"></span>
                  <span class="name" v-else>{{item.name}}</span>
                </div>
                <!-- <span class="name" v-if="item.title === '联系电话'">{{item.name}}</span> -->
                <!-- 1-注册可查看联系方式 2-补充资料 3-实名认证 4-升级为VIP 5-联系客服 0没有按钮提示 -->
                <span class="vip cursor" v-if="item.title === '联系电话' && info.contact_button != 0" @click="upgradeClick(info.contact_button)">
                  {{info.contact_button === 1 ? '注册可查看联系方式' : info.contact_button === 2 ? '补充资料'
                  : info.contact_button === 3 ? '实名认证' : info.contact_button === 4 ? '升级为VIP' : info.contact_button === 5 ? '联系客服' :''}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="affix paddingIn" v-if="annexList.length">
          <div class="border">
            <div class="title">项目附件</div>
          </div>
          <div class="detailsTable">
            <a :href='item' v-for="(item,index) in annexList" :key="index" class="flex_jcsb_ac affixText">
              <div class="name">{{item}}</div>
              <div class="flex_ac download">
                <img src="../../assets/images/icon/xiazai.png" alt="">
                <span>下载</span>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div class="business">
        <div :class="pageHeight ? 'position' : ''">
          <div class="announcerBack projesct" v-if="info.status || info.is_my">
            <div :class="info.status === 0 || info.button === 3 || info.status === 1 || info.status === 3? 'state bottomTop' : 'state'">{{info.status === 2 ? '项目进行中' : info.status === 0 ? '等待投标' : info.status === 1
              ? '等待选标' : info.status === 3 ? '项目完成' : info.status === 5 ? '项目已取消' : info.status === 4 ? '项目已评价' : ''}}</div>
            <el-button class="revise" v-if="info.update_project" @click="projesctRevise">修改项目</el-button>
              <el-popconfirm
                v-if="info.cancel_project"
                title="项目确定取消吗？"
                @confirm="projesctCancel"
            >
              <el-button slot="reference" class="participateInBtn joinBtn finishWidth">取消发布</el-button>
            </el-popconfirm>
            <el-popconfirm
                v-if="info.button === 2"
                title="项目确定放弃吗？"
                @confirm="giveParticipation"
            >
              <el-button slot="reference" class="participateInBtn joinBtn finishWidth">放弃参与</el-button>
            </el-popconfirm>
            <!-- <el-button v-if="info.button === 2" class="participateInBtn finishWidth">放弃参与</el-button> -->
            <el-button v-if="info.button === 8" class="finishWidth" type="info">已评价</el-button>
            <el-button v-if="info.button === 4" class="participateInBtn joinBtn finishWidth" @click="rateNow">立即评价</el-button>
            <el-button v-if="info.button === 3" class="participateInBtn joinBtn finishWidth" @click="completion">项目完成</el-button>
          </div>
          <div :class="info.status ? 'announcer announcerBack' : 'announcerBack'" v-if="!info.is_my">
            <div class="border">
              <div class="title">发布者</div>
            </div>
            <div class="content" v-if="info.user">
              <img :src="info.user.avatarUrl || ''" />
              <div class="title">{{info.user.nickName || ''}}</div>
              <el-button type="primary" @click="homePageClick">查看主页</el-button>
            </div>
          </div>
          <div :class="info.status ? 'announcer announcerBack' : 'announcerBack'" v-if="info.is_my && info.tenderuser">
            <div class="border">
              <div class="title">合作方</div>
            </div>
            <div class="content">
              <img :src="info.tenderuser.avatarUrl || ''" />
              <div class="title">{{info.tenderuser.nickName || ''}}</div>
              <el-button type="primary" @click="partnerClick(info.tenderuser.id)">查看主页</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 评分弹框 -->
    <Evaluation :id="info.id" :isevaluateModalShow="isevaluateModalShow" @isevaluatemodalClone="isevaluatemodalClone" />
    <!-- 我要参与弹框 -->
    <Participate :isParticipate="Participate" :project_id="info.id" @participateEvaluate="participateEvaluate" />
    <!-- 修改弹框 -->
    <Revise :isRevise="isRevise" :detail="info" @reviseProjes="reviseProjes" />
  </div>
</template>

<script>
import Evaluation from '@/components/evaluation/evaluation'
import Revise from '@/components/detailsBullet/revise'
import Participate from '@/components/detailsBullet/participate'
import Partner from './partner'
import { ProjecDetails, followCreate, followCancel, indexTender, finish, cancelProject, tenderCancelt } from '@/api/index/index'
export default {
  name: 'detailsProjesct',
  components: {
    Evaluation,
    Revise,
    Participate,
    Partner
  },
  data() {
    return {
      info: {}, // 详情
      annexList: [], // 附件
      userInfo: {}, // 用户信息
      isRevise: false, // 修改弹框
      Participate: false, // 我要参与弹框
      isevaluateModalShow: false, // 评价弹框
      condition: 0, // 0等待选标(修改:中标后不能修改 取消) 1进行中 2项目完成(评价 已评价) 3未中标 4已取消
      role: 0, // 0是我发布的 1是别人发布的
      isRelease: false, // 判断有没有发布
      pageHeight: false,
      detailId: '',
      partnerList: [] // 合作方列表
    }
  },
  computed: {
    fileConet() { // 把数据空格去掉
      return function(item) {
        var reg = /^(\d{3})\d{4}(\d{4})$/
        return item.replace(reg, '$1****$2')
        // return e.replace(/\s+/g, '')
      }
    }
  },
  methods: {
    upgradeClick(type) { // 1-注册可查看联系方式 2-补充资料 3-实名认证 4-升级为VIP 5-联系客服 0没有按钮提示
      switch (type) {
        case 1:
          localStorage.setItem('loginType', 1)
          this.$router.push('/login')
          break;
        case 2:
          this.$message.success('请补充资料')
          this.$router.push('/user/myTracks')
          break;
        case 3:
          this.$message.success('请实名认证')
          this.$router.push('/user/myTracks')
          break;
        case 4:
          this.$message.success('请升级为VIP')
          this.$router.push('/user/myMember')
          break;
      }
    },
    detail() { // 调用详情接口
      const data = {
        id: this.detailId
      }
      ProjecDetails(data).then(res => {
        this.info = res.data
        if (this.info.annex) {
          this.annexList = this.info.annex.split(',')
        }
        const userId = JSON.parse(localStorage.getItem('userInfo')).id
        if (this.info.user) {
          if (this.info.user.id === userId) {
            this.role = 0
          } else {
            this.role = 1
          }
        }
      })
    },
    giveParticipation() { // 放弃项目
      // console.log('11')
      const data = {
        project_id: this.detailId
      }
      tenderCancelt(data).then(res => {
        if (res.code === 1) {
          this.$message.success('放弃成功')
          this.detail()
          // this.$router.push({name: 'detailsProjesct', query: {id}})
        } else {
          this.$message.success(res.mes)
        }
      })
    },
    reviseProjes(type) { // 修改项目成功
      this.detail()
      this.isevaluateModalShow = type
    },
    projesctRevise() { // 修改项目
      this.isRevise = true
    },
    projesctCancel() { // 项目取消
      // console.log('11')
      const data = {
        project_id: this.detailId
      }
      cancelProject(data).then(res => {
        if (res.code === 1) {
          this.$message.success('取消成功')
          this.detail()
          // this.$router.push({name: 'detailsProjesct', query: {id}})
        } else {
          this.$message.success(res.mes)
        }
      })
    },
    isevaluatemodalClone() { // 关闭评价
      this.isevaluateModalShow = false
      // console.log('reta', retaVal)
      // if (retaVal) {
      this.detail()
      // }
    },
    rateNow() { // 立即评价
      this.isevaluateModalShow = true
    },
    completion() { // 项目完成
      const data = {
        project_id: this.detailId
      }
      finish(data).then(res => {
        if (res.code === 1) {
          this.$message.success('项目完成')
          this.detail()
          // this.$router.push({name: 'detailsProjesct', query: {id}})
        } else {
          this.$message.success(res.mes)
        }
      })
    },
    chooseProjesct() {
      this.detail()
    },
    followProject(id) { // 关注项目
      const data = {
        project_id: id
      }
      followCreate(data).then(res => {
        this.detail()
      })
    },
    followCancel(id) { // 取消关注
      const data = {
        project_id: id
      }
      followCancel(data).then(res => {
        this.detail()
      })
    },
    participateClick() { // 我要参与
      if (!this.userInfo) {
        this.$message.warning('请先登录')
        localStorage.setItem('loginType', 0);
        this.$router.push('/login')
        return
      }
      this.Participate = true
    },
    homePageClick() { // 查看主页
      if (!this.userInfo) {
        this.$message.warning('请先登录')
        localStorage.setItem('loginType', 0);
        this.$router.push('/login')
        return
      }
      const id = this.info.user.id
      this.$router.push({name: 'memberInformation', query: {id}})
    },
    partnerClick(id) {
      if (!this.userInfo) {
        this.$message.warning('请先登录')
        localStorage.setItem('loginType', 0);
        this.$router.push('/login')
        return
      }
      this.$router.push({name: 'memberInformation', query: {id}})
    },
    listClick() {
      const data = {
        project_uid: this.userInfo.id, // 企业用户ID
        page: 1,
        psize: 100000,
        project_id: this.detailId // 项目ID
      }
      indexTender(data).then(res => {
        this.partnerList = res.data.list
        // console.log(res)
      })
    },
    participateEvaluate() {
      this.Participate = false
      this.listClick()
      this.detail()
    },
    handleScroll() { // 页面滑动的距离
      let scrollTop = document.documentElement.scrollTop
      // 当页面滑动的距离大于90px时元素显示，否则不显示
      if (scrollTop >= 255) {
        this.pageHeight = true
      } else {
        this.pageHeight = false
      }
    }
  },
  mounted() {
    this.detailId = this.$route.query.id
    this.detail()
    this.listClick()
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('setItemEvent', (e) => {
      // console.log('111', JSON.parse(localStorage.getItem('userInfo')))
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    })
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
  },
  // 组件销毁前
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style lang="scss" scoped>
/deep/ {
  .el-button+.el-button{
    margin-left: 0;
  }
}
.joinBtn{
    // width: 160px;
    margin-top: 25px;
    background: #F9D900;
    &:hover{
      color: #333;
      background: #F9BB00;
    }
  }
  .businessBox{
    width: 1200px;
    margin: auto;
    align-items: flex-start;
    margin-top: 12px;
    .widthBox{
      width: 100%;
    }
    .border{
      padding: 24px;
      border-bottom: 1px solid #EEE;
      .title{
        font-size: 18px;
        border-left: 4px solid #1166CF;
        padding-left: 12px;
      }
    }
    .affix{
      // margin: 12px 0;
      .affixText{
        line-height: 30px;
        .name{
          color: #1166CF;
          font-size: 16px;
        }
        .download{
          cursor: pointer;
        }
        span{
          font-size: 16px;
          color: #0078FF;
          margin-left: 9px;
        }
      }
    }
    .paddingIn{
      flex: 1;
      background: #fff;
      margin-bottom: 12px;
      .borderBox{
        flex: 1;
         border: 1px solid #E8E8E8;
         margin: 24px;
         .back{
           background: #F6F7F8;
         }
         .nameBox{
           flex: 1;
           text-align: center;
           border-bottom: 1px solid #E8E8E8;
           color: #333333;
           font-size: 16px;
           line-height: 48px;
           position: relative;
            .vip{
              position: absolute;
              right: 10px;
              font-size: 16px;
              font-family: Source Han Sans CN;
              color: #F11D1D;
              line-height: 30px;
              top: 10px;
              border-bottom: 1px solid #F11D1D;
            }
         }
         .title{
          width: 184px;
          line-height: 48px;
          text-align: center;
          color: #666666;
          font-size: 16px;
          border-right: 1px solid #E8E8E8;
          border-bottom: 1px solid #E8E8E8;
        }
      }
      .detailsTable{
        flex: 1;
        // width: 100%;
        padding: 24px;
         /deep/{
          .el-table--enable-row-transition .el-table__body td{
            text-align: center;
          }
        }
      }
    }
    .business{
      width: 240px;
      margin-left: 12px;
      flex-shrink: 0;
      .projesct{
        padding: 24px 20px;
        text-align: center;
        .state{
          font-size: 20px;
          font-weight: bold;
          color: #1166CF;
        }
        .bottomTop{
          margin-bottom: 24px;
        }
        .finishWidth{
          width: 200px;
          font-size: 16px;
        }
        .revise{
          width: 200px;
          margin: 0 auto 12px;
        }
      }
      .announcer{
        margin-top: 12px;
      }
      .announcerBack{
        background: #fff;
      }
      .position{
        position: fixed;
        top: 80px;
        width: 240px;
        z-index: 100;
      }
      .content{
        padding: 24px;
        font-size: 16px;
        text-align: center;
        img{
          width: 48px;
          height: 48px;
          // background: #E1F5FF;
          border-radius: 50%;
          object-fit: cover;
        }
        .title{
          font-size: 16px;
          margin: 15px 0 23px 0;
        }
      }
    }
  }
  .basic{
    position: relative;
    height: 240px;
    .datailsBox{
      width: 1200px;
      margin: auto;
      position: relative;
      padding: 20px 0;
      // color: #fff;
      .right{
        text-align: right;
        .focusOn{
          justify-content: flex-end;
          margin-top: 35px;
          span{
            margin-left: 5px;
          }
          .img{
            width: 25px;
            height: 25px;
          }
        }
        .participateInBtn{
          border: none;
          color: #333;
        }
      }
      .left{
        img{
          width: 306px;
          height: 200px;
          background: #FCFDFE;
          object-fit: cover;
        }
        .introduce{
          margin-left: 31px;
          margin-top: 25px;
          .dataTiem{
            margin-top: 50px;
            .el-icon-view{
              margin-left: 32px;
            }
            .tiem{
              margin-left: 10px;
            }
          }
          .name{
            font-size: 24px;
          }
          .assort{
            font-size: 16px;
            // width: 128px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border: 1px solid #333333;
            border-radius: 16px;
            margin-top: 23px;
          }
        }
      }
    }
    .bg{
      position: absolute;
      top: 0;
      width: 100%;
      height: 240px;
    }
  }
</style>
