<template>
  <Modal modalTitle="我要参与" modalWidth="800px" :modalShow="isevaluateModal" @modalClone="isevaluatemodalClone">
    <div class="evaluate-center" slot="modalContent">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="name">
          <div class="target">您的姓名 <span>*</span></div>
          <el-input v-model="ruleForm.name" placeholder="请输入您的姓名"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <div class="target">联系电话 <span>*</span></div>
          <el-input v-model="ruleForm.phone" placeholder="请输入您的联系电话"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="target">备注</div>
          <el-input type="textarea" v-model="ruleForm.desc" autosize placeholder="请输入备注内容"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="widthAuto">
            <el-button class="buttomBtn widthAuto joinBtn" @click="orderEvaluate('ruleForm')">提交</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/basic/modal'
import rules from '@/views/detailsProjesct/rules'
import { Tender } from '@/api/index/index'
export default {
  name: 'evaluation',
  props: {
    isParticipate: {
      type: Boolean,
      default: false
    },
    project_id: {
      type: Number,
      default: 0
    }
  },
  components: {
    Modal
  },
  mounted() {
    if (localStorage.getItem('userInfo')) {
      const data = JSON.parse(localStorage.getItem('userInfo'))
      this.ruleForm.name = data.nickName
      this.ruleForm.phone = data.phone
      // console.log('11', data)
    }
  },
  watch: {
    isParticipate(val) {
      this.isevaluateModal = val
    }
  },
  data() {
    return {
      isevaluateModal: false,
      ruleForm: {
        name: '',
        phone: '',
        desc: ''
      },
      rules: rules
    }
  },
  methods: {
    orderEvaluate(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = {
            project_id: this.project_id,
            name: this.ruleForm.name,
            phone: this.ruleForm.phone,
            remarks: this.ruleForm.desc
          }
          Tender(data).then(res => {
            // console.log('res', res)
            if (res.code === 1) {
              this.$message.success('提交成功')
              this.$emit('participateEvaluate')
            } else {
              this.$message.success(res.mes)
            }
          })
        } else {
          // console.log('error submit!!');
          return false;
        }
      })
      // this.$emit('participateEvaluate', formName)
    },
    isevaluatemodalClone() { // 关闭评价弹框
      this.isevaluateModal = false;
      this.$emit('participateEvaluate')
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/{
    .el-input__inner{
      background: #FCFCFC;
    }
    .el-textarea .el-textarea__inner{
      min-height: 120px !important;
       background: #FCFCFC;
    }
  }
  .joinBtn{
    // width: 160px;
    margin-top: 25px;
    background: #F9D900;
    &:hover{
      color: #333;
      background: #F9BB00;
    }
  }
  .evaluate-center{
    padding: 0 20px;
    .target{
      color: #666;
      font-size: 16px;
      span{
        color: #EF2828;
      }
    }
    .widthAuto{
      text-align: center;
      margin: 20px;
    }
  }
</style>
