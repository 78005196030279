<template>
  <Modal modalTitle="立即评价" modalWidth="800px" :modalShow="isevaluateModal" @modalClone="isevaluatemodalClone">
    <div class="evaluate-center" slot="modalContent">
      <h5>您对合作方还满意吗？</h5>
      <ul class="flex_jcsb">
        <li @click="score('bad')" :class="scoreStatus === 'bad' ? 'scoreAction' : ''"><span>差评</span></li>
        <li @click="score('hinaReview')" :class="scoreStatus === 'hinaReview' ? 'scoreAction' : ''"><span>中评</span></li>
        <li @click="score('praise')" :class="scoreStatus === 'praise' ? 'scoreAction' : ''"><span>好评</span></li>
      </ul>
      <el-rate v-model="retaVal" :colors="retaColors"></el-rate>
      <div class="explain">
        <el-input type="textarea" :rows="5" v-model="evaluateVal" placeholder="您想对雇主说什么呢" resize="none"></el-input>
      </div>
      <div class="checked">
        <el-checkbox @change="changeClick" v-model="checked">匿名评价</el-checkbox>
      </div>
      <el-button class="buttomBtn" type="primary" @click="orderEvaluate">提交</el-button>
      <!-- <el-button class="buttomBtn" @click="orderEvaluate">提交</el-button> -->
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/basic/modal'
import {evaluCreate} from '@/api/index/index'
export default {
  name: 'evaluation',
  props: {
    isevaluateModalShow: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    }
  },
  components: {
    Modal
  },
  data() {
    return {
      is_anonymous: 0,
      checked: false,
      isevaluateModal: false,
      scoreStatus: null, // 评分状态
      retaVal: null, // 评分星星
      evaluateVal: '',
      retaColors: ['#FFDB25', '#FFDB25', '#FFDB25'] // 评分阶段颜色
    }
  },
  watch: {
    isevaluateModalShow(val) {
      this.isevaluateModal = val
    }
  },
  mounted() {
    if (this.isevaluateModalShow) {
      this.$nextTick(() => {
        this.isevaluateModal = true
      })
    }
  },
  methods: {
    changeClick() {
      if (this.checked) {
        this.is_anonymous = 1
      } else {
        this.is_anonymous = 0
      }
    },
    isevaluatemodalClone() { // 关闭评价弹框
      this.$emit('isevaluatemodalClone')
      this.isevaluateModal = false;
    },
    score(type) { // 评分
      this.scoreStatus = type;
    },
    orderEvaluate() {
      if (!this.scoreStatus) {
        this.$message.error('请先进行评价选择')
        return false;
      }
      const paramster = {
        project_id: this.id,
        type: this.scoreStatus === 'bad' ? 1 : this.scoreStatus === 'hinaReview' ? 2 : 3,
        content: this.evaluateVal,
        star: this.retaVal,
        is_anonymous: this.is_anonymous
      }
      evaluCreate(paramster).then(res => {
        this.$message.success('评价成功')
        // this.isevaluateModalShow = false;
        this.scoreStatus = null;
        this.evaluateVal = '';
        this.retaVal = 0;
        this.$emit('isevaluatemodalClone')
        // this.getOrderDetail()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.evaluate-center {
    height: calc(560px - 52px);
    text-align: center;
    .checked{
      margin-top: -30px;
      margin-bottom: 30px;
    }
    /deep/ .el-rate__icon {
      font-size: 28px;
    }
    .explain {
      padding: 0 120px;
      margin-top: 35px;
      margin-bottom: 60px;
    }
    .scoreAction {
      background: #FFDB25;
      color: #333;
    }
    h5 {
      font-size: 18px;
    }
    ul {
      padding: 0 180px;
      margin-bottom: 38px;
    }
    ul li {
      width: 100px;
      height: 40px;
      line-height: 40px;
      font-size: 15px;
      color: #666;
      background: #EEEEEE;
      cursor: pointer;
      &:hover {
        background: #FFDB25;
        color: #333;
      }
    }
  }
  </style>
